import React, { useState, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { styled } from 'styles';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import Modal from 'components/Modal';
import AddCardForm from './AddCardForm';
import ConfirmDeleteCard from './ConfirmDeleteCard';
import DisputeForm from './DisputeForm';
import MonthTransactions from './MonthTransactions';
import PaymentCard from './PaymentCard';
import Context from '../context';
import iconTrash from 'assets/icons/trash.svg';

const stripeKey = process.env.GATSBY_STRIPE_KEY || '';

dayjs.extend(isSameOrBefore);

const groupTransactionsByMonth = (transactions) => {
  if (!transactions?.length) return [];
  const currentDate: Dayjs = dayjs();
  let startDate: Dayjs = currentDate;
  const months: Dayjs[] = [];
  const groupedByMonth = {};

  transactions.forEach((item) => {
    const createdOn = dayjs.unix(item.createdOn);
    if (createdOn.isBefore(dayjs(startDate))) {
      startDate = createdOn;
    };
  });
  while (startDate.isSameOrBefore(currentDate, 'month')) {
    months.push(startDate.startOf('month'));
    startDate = startDate.add(1, 'month');
  }

  months.reverse().forEach(month => {
    const monthToString = month.format('MMMM YYYY');
    groupedByMonth[monthToString] = transactions.filter(item => {
      const itemMonth = dayjs.unix(item.createdOn).format('MMMM YYYY');
      return itemMonth === monthToString;
    });
  });

  return groupedByMonth;
};

const Payments = () => {
  const { cards, transactions, makeCardPrimary, removeCard, submitDispute } = useContext(
    Context
  );
  const [disputeId, setDisputeId] = useState<any>('');
  const [cardId, setCardId] = useState<string>('');
  const [newCartPopupOpen, setNewCartPopupOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [openDisputeModal, setOpenDisputeModal] = useState(false);

  const transByMonth = groupTransactionsByMonth(transactions);

  const openRemoveCard = card => {
    setCardId(card);
    setConfirmModalOpen(true);
  };

  const disputeCharge = (e, row) => {
    e.preventDefault();
    setDisputeId(row);
    setOpenDisputeModal(true);
  };

  const confirmRemoveCard = async () => {
    await removeCard(cardId);
    setConfirmModalOpen(false);
  };

  const handleSubmitDispute = async (disputeDetails: { description: string; }) => {
    const data = {
      transaction_id: disputeId.original.order_id,
      description: disputeDetails.description
    };
    await submitDispute(data);
    setOpenDisputeModal(false);
  };

  const paymentsLabel = (
    <div className="payments-accordeon">
      <h2>Payment Details</h2>
      <Button
        simple
        className="addNewCardButton"
        onClick={e => {
          e.stopPropagation();
          setNewCartPopupOpen(true);
        }}
        data-cy="button_add_new_card">
        + Add New
      </Button>
    </div>
  );

  return (
    <StyledPayments className="payments">
      <Accordion label={paymentsLabel} defaultOpen data-cy="accordeon_payments">
        {!cards.length ? (
          <div className="empty-content">No payments methods.</div>
        ) : (
            <ul className="cardsList">
              {cards.map(card => {
                return (
                  <PaymentCard
                    key={card.id}
                    data={card}
                    onSelect={() => makeCardPrimary(card.id)}
                    onRemove={() => openRemoveCard(card.id)}
                  />
                );
              })}
            </ul>
          )}
      </Accordion>
      <Accordion label="Transaction History" defaultOpen data-cy="accordeon_transaction_history">
        {transactions.length === 0 ? (
          <div className="empty-content">No transaction history yet.</div>
        ) : (
            <ul className="transactions">
              {Object.keys(transByMonth).map((month, i) => (
                <MonthTransactions month={month} items={transByMonth[month]} disputeCharge={disputeCharge} key={i} />
              ))}
            </ul>
          )}
      </Accordion>
      <Modal
        modalTitle="Add new card"
        open={newCartPopupOpen}
        onClose={() => setNewCartPopupOpen(false)}>
        <Elements stripe={loadStripe(stripeKey)}>
          <AddCardForm onSubmit={() => setNewCartPopupOpen(false)} />
        </Elements>
      </Modal>
      <Modal
        modalTitle="Delete card"
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}>
        <ConfirmDeleteCard
          onCancel={() => setConfirmModalOpen(false)}
          onConfirm={confirmRemoveCard}
        />
      </Modal>
      <Modal
        modalTitle="Charge Inquiry"
        open={openDisputeModal}
        onClose={() => setOpenDisputeModal(false)}>
        <DisputeForm onSubmit={handleSubmitDispute} />
      </Modal>
    </StyledPayments>
  );
};

export default Payments;

export const StyledPayments = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-width: 300px;
  }

  & {
    padding: 0px;
    font-family: Inter;
    button[type='submit'] {
      width: 100%;
    }

    .payments-accordeon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .accordion {
      margin-bottom: 18px;
      border-bottom: none;
    }

    .accordion-head {
      padding: 14px 0;
      margin: 0 0 0 -24px;
      .label {
        width: 100%;
      }
    }

    .open .accordion-head {
      .icon-plus-mini {
        fill: #000;
      }
    }

    .cardsList {
      padding: 0;
      margin-top: 0;
    }

    .empty-content {
      margin-top: 2px;
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
      color: #8b8b8b;
    }

    .month-transactions {
      padding: 0;
      list-style-type: none;
    }

    .transactions {
      margin-top: -20px;
      padding-bottom: 50px;
      padding-left: 0;
    }

    .transactions > * + .month {
      border-top: 1px solid ${props => props.theme.colors.gray};
    }

    .selectPrimary {
      padding-top: 5px;
      width: 60px;
      display: flex;
      align-items: baseline;
      & > * {
        margin-right: -8px;
      }
    }

    .primaryLabel {
      font-size: 9px;
      line-height: 14px;
      color: #8b8b8b;
      font-weight: bold;
    }

    .addNewCardButton {
      margin-left: auto;
      color: ${props => props.theme.colors.red};
      font-weight: normal;
    }

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 40px;
      padding-top: 5px;
    }

    .monthTitle {
      padding: 20px 0;
    }

    * + .transItem {
      border-top: 1px solid #cdcdcd;
    }

    .sum {
      font-weight: bold;
      text-align: right;
    }

    .transItem:last-child {
      padding-bottom: 50px;
    }

    .priceAndRefund {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
    }

    .monthTitle {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    .transactionHistory {
      margin-top: 65px;
    }

    .cardItem {
      &.active {
        border: 2px solid #df1721;

        .primaryLabel {
          color: #df1721;
        }
      }
      margin-bottom: 10px;
      background: #f8f8f8;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 14px 18px;
      position: relative;

      & > * {
        &.mask,
        &.expDate {
          font-size: 20px;
          line-height: 24px;
          color: #8b8b8b;
          padding: 3px 10px;
        }
        &.trash {
          border: none;
          width: 16px;
          height: 16px;
          background: url(${iconTrash}) no-repeat;
          background-size: contain;
          cursor: pointer;
          filter: grayscale(100%);
        }
      }
      .card-brand {
        font-size: 50px;
        font-weight: 900;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        left: 104px;
        opacity: 0.1;
        color: #8b8b8b;
      }

      @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
        flex-wrap: wrap;

        border: 2px solid #8b8b8b;

        .selectPrimary {
          width: 100%;
        }

        .mask,
        .expDate {
          padding-left: 0;
          font-size: 16px;
        }
        .expDate {
          width: 50%;
        }
        .mask {
          margin: 8px 0;
        }

        .cardItem {
          &.active {
            border: 2px solid #df1721;

            .primaryLabel {
              color: #df1721;
            }
          }
          margin-bottom: 10px;
          background: #f8f8f8;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          padding: 14px 18px;
          position: relative;

          & > * {
            &.mask,
            &.expDate {
              font-size: 20px;
              line-height: 24px;
              color: #8b8b8b;
              padding: 3px 10px;
            }
            &.trash {
              border: none;
              width: 16px;
              height: 16px;
              background: url(${iconTrash}) no-repeat;
              background-size: contain;
              cursor: pointer;
              filter: grayscale(100%);
            }
          }
          .card-brand {
            font-size: 50px;
            font-weight: 900;
            text-transform: uppercase;
            position: absolute;
            bottom: 0;
            left: 104px;
            opacity: 0.1;
            color: #8b8b8b;
          }

          @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
            flex-wrap: wrap;

            border: 2px solid #8b8b8b;

            .selectPrimary {
              width: 100%;
            }

            .mask,
            .expDate {
              padding-left: 0;
              font-size: 16px;
            }
            .expDate {
              width: 50%;
            }
            .mask {
              margin: 8px 0;
            }

            .card-brand {
              left: auto;
              right: 0;
            }
            .trash {
              position: absolute;
              top: 20px;
              right: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;

    .accordion-head {
      margin: 0;
    }
  }
`;
