import React from 'react';
import { useForm } from 'react-hook-form';

import { styled } from 'styles';
import Button from 'components/Button';
import Input from 'components/Input';

const DisputeForm = ({ onSubmit }) => {
  const { register, errors, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      description: ''
    }
  });

  return (
    <StyledDisputeForm className="dispute-form" onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register({ required: 'Please describe the issue with the charge.' })}
        as="textarea"
        name="description"
        label="Issue description"
        placeholder="Please describe your issue"
        error={errors.description?.message}
        data-cy="input_description"
      />
      <Button
        large
        disabled={!formState.isDirty || !formState.isValid}
        data-cy="button_submit_dispute">
        Submit
      </Button>
    </StyledDisputeForm>
  );
};

export default DisputeForm;

const StyledDisputeForm = styled.form`
  .input {
    width: 100%;
  }
  .input-wrapper {
    textarea.input-component {
      min-height: 120px;
      padding: 12px 8px;
      resize: none;
    }
  }

  .button {
    margin: 16px 0 0;
    display: block;
  }
`;
