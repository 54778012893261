import React, { useMemo } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent
} from '@stripe/stripe-js';

import { styled } from 'styles';
import clsx from 'clsx';
import { InputProps } from 'components/Input';

const styles = {
  style: {
    base: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: 'Inter, apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      color: '#000000',
      '::placeholder': {
        color: '#8b8b8b'
      }
    },
    invalid: {
      color: '#df1721'
    }
  }
};
interface StripeInputProps extends Omit<InputProps, 'onChange'> {
  onBlur?: () => any;
  onFocus?: () => any;
  type: 'number' | 'expiry' | 'cvc';
  onChange?: (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => any;
}

const StripeInput: React.FC<StripeInputProps> = ({
  className,
  type,
  id,
  label,
  error,
  before,
  after,
  placeholder,
  disabled,
  wrapperProps,
  ...props
}) => {
  const Component = useMemo(() => {
    switch (type) {
      case 'expiry':
        return CardExpiryElement;
      case 'cvc':
        return CardCvcElement;
      case 'number':
      default:
        return CardNumberElement;
    }
  }, [type]);

  return (
    <StyledInputWrapper
      className={clsx('input stripe', { before, after, error }, className)}
      {...wrapperProps}>
      {label ? (
        <label htmlFor={id} className="input__label">
          {label}
        </label>
      ) : null}
      <div className="input__component-wrapper">
        <span className="input__component-wrapper__before">{before}</span>
        <Component
          className="input__component"
          options={{ placeholder, disabled, showIcon: true, ...styles }}
          {...props}
        />
        <span className="input__component-wrapper__after">{after}</span>
      </div>
    </StyledInputWrapper>
  );
};

export default StripeInput;

const StyledInputWrapper = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  width: 176px;

  label, .input__label {
    font-size: 10px;
  }

  &.before input {
    padding-left: 16px;
  }

  &.after input {
    padding-right: 28px;
  }

  &.error input,
  &.error textarea {
    border-color: ${props => props.theme.colors.red};
  }

  .input__component-wrapper {
    width: 100%;

    &__before,
    &__after {
      position: absolute;
      margin-top: 2.5px;
      top: 50%;
      pointer-events: none;
    }

    &__before {
      left: 8px;
    }

    &__after {
      right: 8px;
    }
  }

  .input__component {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.borderColor};
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 0 8px;
    text-overflow: ellipsis;
    outline: none;
    font-weight: lighter;

    &:hover {
      border: 1px solid #000;
    }

    .__PrivateStripeElement {
      width: 100%;
    }
  }

  label {
    display: block;
    font-weight: 400;
    margin-bottom: 4px;
  }
`;
