import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import notify from 'notify';
import { styled } from 'styles';
import { tryGetFirstError } from 'utils/requests';
import Button from 'components/Button';
import Input from 'components/Input';
import { updateCompanyInfo } from '../api';
import Context from '../context';
interface CompanyInfoProps {
  defaultValues?: CompanyInfo;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ defaultValues }) => {
  const { register, errors, getValues, handleSubmit, reset, formState } = useForm({
    defaultValues,
    mode: 'onChange'
  });
  const { fetchCompanyInfo } = useContext(Context);
  const hasErrors = Object.keys(errors).length > 0;

  const submit = async values => {
    try {
      await updateCompanyInfo(values);
      fetchCompanyInfo();
      reset(getValues());
      notify('Profile changes saved');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  return (
    <StyledGeneralUserSetting className="company-info">
      <h5 className="company-info__block-title">Company Information</h5>
      <form className="company-info__form" onSubmit={handleSubmit(submit)}>
        <Input
          ref={register({ required: 'Required' })}
          name="companyName"
          error={errors.companyName?.message}
          label="Company Name"
          placeholder="Enter Company Name"
        />
        <Input
          ref={register({ required: 'Required' })}
          name="companyMlsId"
          error={errors.companyMlsId?.message}
          label="Company MLS ID"
          placeholder="Enter Company MLS ID"
        />
        <Input
          ref={register({ required: 'Required' })}
          name="companyAddress"
          error={errors.companyAddress1?.message}
          label="Company Address Line 1"
          placeholder="Company Address Line 1"
        />
        <Input
          ref={register({ required: 'Required' })}
          name="companyCity"
          error={errors.companyCity?.message}
          label="Company City"
          placeholder="Company City"
        />
        <Input
          ref={register({ required: 'Required' })}
          name="companyZip"
          error={errors.companyZip?.message}
          label="Company Zip"
          placeholder="Company Zip"
        />
        <div>
          <Button disabled={!formState.isDirty || hasErrors || formState.isSubmitting}>
            Save Changes
          </Button>
        </div>
      </form>
    </StyledGeneralUserSetting>
  );
};

export default CompanyInfo;

const StyledGeneralUserSetting = styled.div`
  width: 100%;
  max-width: 640px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .company-info {
    &__block-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;
      margin: 0 0 12px;
    }

    &__form {
      width: 100%;
      .input {
        margin: 0 20px 20px 0;
        width: 300px;

        &:last-of-type {
          margin: 0 0 20px;
        }
        @media (max-width: ${props => props.theme.breakpoints.sm}) {
          width: 100%;
          margin: 0 0 20px;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
    .company-info {
      &__info-block {
        width: 100%;
      }
    }
  }
`;
