import React, { useState, useCallback, useEffect } from 'react';

import notify from 'notify';
import {
  getProfile,
  getTransactions,
  getCompanyInfo,
  updateEmailSignature,
  addNewCard as addNewCardRequest,
  setPrimaryCard,
  removeCard as removeCardRequest,
  getPaymentCards
} from './api';

interface ContextValues {
  profileData?: Profile;
  companyInfo?: CompanyInfo;
  fetchCompanyInfo: () => void;
  cards: Array<{ [key: string]: any }>;
  transactions: Array<Transaction>;
  disablePayment: boolean;
  setDisablePayment: React.Dispatch<React.SetStateAction<boolean>>;
  fetchProfileData: () => Promise<void>;
  fetchTransactionsData: () => Promise<void>;
  fetchPaymentCards: () => Promise<void>;
  changeProfileData: (value: Partial<Profile>) => void;
  changeEmailSignature: (value: string) => void;
  changeEmailNotifications: (value: boolean) => void;
  onSelectTitleCompanies: (value: string[]) => void;
  onSelectSurveyors: (value: string[]) => void;
  addNewCard: (value: { tokenId: string }) => void;
  makeCardPrimary: (value: string) => void;
  removeCard: (value: string) => void;
  submitDispute: (value: { description: string }) => void;
}

const Context = React.createContext({} as ContextValues);

export const ContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState<Profile>();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>();
  const [cards, setCards] = useState<Array<{ [key: string]: any }>>([]);
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [disablePayment, setDisablePayment] = useState<boolean>(false);

  const fetchProfileData = useCallback(async () => {
    try {
      const profileData = await getProfile();
      setProfileData(profileData);
    } catch (err) {
      notify(err.message);
    }
  }, [getProfile]);

  const fetchTransactionsData = useCallback(async () => {
    try {
      const data = await getTransactions();
      setTransactions(data);
    } catch (err) {
      notify(err.message);
    }
  }, [getTransactions]);

  const fetchPaymentCards = useCallback(async () => {
    try {
      const cards = await getPaymentCards();
      setCards(cards);
    } catch (err) {
      notify(err.message);
    }
  }, [getPaymentCards]);

  const fetchCompanyInfo = useCallback(async () => {
    try {
      const companyInfo = await getCompanyInfo();
      setCompanyInfo(companyInfo);
    } catch (err) {
      notify(err.message);
    }
  }, [getCompanyInfo]);

  const notifySuccess = () => notify('Profile changes saved.');

  const changeProfileData = newData => setProfileData(data => ({ ...data, ...newData }));

  const changeEmailSignature = async (value: string) => {
    try {
      const data = await updateEmailSignature(value);
      changeProfileData({ emailSignature: data });
      notifySuccess();
    } catch (err) {
      notify(err.message);
    }
  };

  const changeEmailNotifications = async (value: boolean) => {
    // try {
    //   await updateNotificationSettings({ deadlinesNotifications: value });
    //   changeProfileData({ deadlinesNotifications: value });
    //   notifySuccess();
    // } catch (err) {
    //   notify(err.message);
    // }
  };

  const onSelectTitleCompanies = async (data: Array<string>) => {
    // try {
    //   await updateTitleCompanies(data);
    //   const titleCompaniesSelected = profileData?.titleCompanies.filter(item =>
    //     data.includes(item.value)
    //   );
    //   changeProfileData({ titleCompaniesSelected });
    //   notifySuccess();
    // } catch (err) {
    //   notify('Failed to update title companies');
    // }
  };

  const onSelectSurveyors = async (data: Array<string>) => {
    // try {
    //   await updateSurveyors(data);
    //   const surveyorsSelected = profileData?.surveyors.filter(item => data.includes(item.value));
    //   changeProfileData({ surveyorsSelected });
    //   notifySuccess();
    // } catch (err) {
    //   notify('Failed to update surveyors');
    // }
  };

  const addNewCard = async data => {
    try {
      const card = await addNewCardRequest(data.tokenId);
      const updatedCards = [
        ...cards.map(({ primary_card, ...card }) => ({
          primary_card: false,
          ...card
        })),
        card
      ];
      setCards(updatedCards);
    } catch (err) {
      notify(err.message);
    }
  };

  const makeCardPrimary = async cardId => {
    try {
      await setPrimaryCard(cardId);
      const cardIndex = cards.findIndex(card => card.id === cardId);
      const card = cards[cardIndex];
      const newCards = cards.map(card => ({ ...card, primary_card: 0 }));
      newCards.splice(cardIndex, 1, { ...card, primary_card: 1 });
      setCards(newCards);
      notify('Card was successfully updated');
    } catch (err) {
      notify(err);
    }
  };

  const removeCard = async cardId => {
    try {
      await removeCardRequest(cardId);
      setCards(cards.filter(card => card.id !== cardId));
      notify('Card was successfully removed');
    } catch (err) {
      notify(err.message);
    }
  };

  const submitDispute = async (values: { description: string }) => {
    // try {
    //   await submitDisputeRequest(values);
    //   notify('Your inquiry has been submitted');
    // } catch (err) {
    //   console.dir(err);
    // }
  };

  useEffect(() => {
    if (profileData?.role === 'admin') fetchCompanyInfo();
  }, [fetchCompanyInfo, profileData]);

  const values: ContextValues = {
    profileData,
    companyInfo,
    fetchCompanyInfo,
    cards,
    transactions,
    disablePayment,
    setDisablePayment,
    fetchProfileData,
    fetchTransactionsData,
    fetchPaymentCards,
    changeProfileData,
    changeEmailSignature,
    changeEmailNotifications,
    onSelectTitleCompanies,
    onSelectSurveyors,
    addNewCard,
    makeCardPrimary,
    removeCard,
    submitDispute
  };
  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export default Context;
