import React from 'react';

import { styled } from 'styles';
import Button from 'components/Button';

const ConfirmDeleteCard = ({ onCancel, onConfirm }) => {
  return (
    <StyledConfirmDeleteCard>
      <p>Are you sure you want to remove this card?</p>
      <Button
        secondary
        onClick={onCancel}
        large
        className="cancel-btn"
        data-cy="button_cancel_delete">
        No
      </Button>
      <Button onClick={onConfirm} large data-cy="button_confirm_delete">
        Yes
      </Button>
    </StyledConfirmDeleteCard>
  );
};

export default ConfirmDeleteCard;

const StyledConfirmDeleteCard = styled.div`
  p {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 24px;
  }

  .button {
    display: block;
    & ~ * {
      margin-top: 16px;
    }
  }
`;
