import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { styled } from 'styles';
import Button from 'components/Button';
import Context from '../context';

const EmailSignatureForm = () => {
  const { profileData, changeEmailSignature } = useContext(Context);
  const defaultValues = { emailSignature: profileData?.emailSignature };
  const { register, handleSubmit, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const submit = ({ emailSignature }) => {
    changeEmailSignature(emailSignature);
  };

  return (
    <StyledEmailSignatureForm className="email-signature">
      <form className="email-signature__form" onSubmit={handleSubmit(submit)}>
        <p className="email-signature__description">
          Set email signature for your account. <br />
          It will be displayed at the end of emails that you sent.
        </p>

        <textarea
          ref={register}
          name="emailSignature"
          placeholder="Enter Email Signature"
          className="email-signature__signature"
          data-cy="textarea_signature"
        />

        <Button
          disabled={!formState.isDirty || formState.isSubmitted}
          data-cy="button_submit_signature">
          Save Changes
        </Button>
      </form>
    </StyledEmailSignatureForm>
  );
};

export default EmailSignatureForm;

const StyledEmailSignatureForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  max-width: 340px;

  .email-signature {
    &__description {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
    }

    &__signature {
      width: 100%;
      height: 100px;
      padding: 8px 12px;
      margin: 0 0 32px;
      font-size: 12px;
      line-height: 16px;
      overflow-y: auto;
      resize: none;
      border: 1px solid ${props => props.theme.colors.seashell};
      border-radius: ${props => props.theme.misc.borderRadius};
      outline: none;
      &:hover,
      &:focus {
        border-color: #000;
      }
    }
  }
`;
