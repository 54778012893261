import React from 'react';
import { Controller, ValidationRules, Control } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import Input, { InputProps } from 'components/Input';

interface MaskInputControllerProps extends InputProps {
  control: Control;
  mask: string;
  name: string;
  rules?: ValidationRules;
}

const MaskInputController: React.FC<MaskInputControllerProps> = ({
  control,
  name,
  mask,
  rules,
  ...props
}) => {
  const maskProps = { as: MaskedInput, mask };
  return (
    <Controller
      control={control}
      rules={rules}
      defaultValue=""
      name={name}
      render={({ value, ...controllerProps }) => (
        <Input
          value={value || ''}
          label="Office Line"
          placeholder="Enter Office Line"
          {...props}
          {...maskProps}
          {...controllerProps}
        />
      )}
    />
  );
};

export default MaskInputController;
