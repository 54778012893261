import React from 'react';

import { styled } from 'styles';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';

const PaymentCard = ({ data, onSelect, onRemove }) => {
  const brandIcon =
    data.brand === 'Visa' ? (
      <Icon name="visa-card" />
    ) : data.brand === 'MasterCard' ? (
      <Icon name="master-card" />
    ) : null;

  return (
    <StyledPaymentCard className="payment-card">
      <div className="payment-card__brand">{brandIcon}</div>
      <div className="payment-card__info">
        <div className="payment-card__mask">XXXX-XXXX-XXXX-{data.last4}</div>
        <div className="payment-card__expiry">Expires: {data.exp_date}</div>
        <Checkbox
          checked={data.primary_card}
          label="Set as default payment method"
          onChange={onSelect}
          data-cy={`radio_select_primary_${data.id}`}
        />
      </div>
      <div className="payment-card__controls">
        <Button simple onClick={onRemove} data-cy={`button_remove_${data.id}`}>
          <Icon name="trash" size={16} />
        </Button>
      </div>
    </StyledPaymentCard>
  );
};

export default React.memo(PaymentCard);

const StyledPaymentCard = styled.div`
  width: 300px;
  display: flex;
  align-items: flex-start;
  padding: 10px 12px;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.misc.borderRadius};

  & ~ * {
    margin-top: 8px;
  }

  .payment-card {
    &__brand {
      margin-right: 12px;
    }

    &__mask {
      font-weight: 500;
      margin-bottom: 6px;
    }

    &__expiry {
      margin-bottom: 12px;
    }

    &__controls {
      margin-left: auto;

      .icon {
        fill: ${props => props.theme.colors.grayDark};
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: auto;
  }
`;
