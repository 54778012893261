import React, { useContext } from 'react';
import pluralize from 'pluralize';

import { styled } from 'styles';
import Dropdown from 'components/Dropdown';
import Context from '../context';

const Vendors = () => {
  const { profileData, onSelectTitleCompanies, onSelectSurveyors } = useContext(Context);

  if (!profileData) return null;

  const titleCompaniesSelected = profileData.titleCompaniesSelected?.map(item => item.value);
  const surveyorsSelected = profileData.surveyorsSelected?.map(item => item.value);

  const titleCompaniesOptions = profileData.titleCompanies?.map(item => ({
    value: item.value,
    text: item.name
  }));

  const surveyorsOptions = profileData.surveyors?.map(item => ({
    value: item.value,
    text: item.name
  }));

  const getDropdownText = (name, length, options) => {
    if (!length) return 'Select';
    if (length === options.length) return `All ${pluralize(name, length)}`;
    return `${length} ${pluralize(name, length)}`;
  };

  return (
    <StyledVendors className="vendors">
      <p className="vendors__description">
        Please select the title companies you are affiliated with.
      </p>

      <Dropdown
        label="Title Companies"
        value={titleCompaniesSelected}
        options={titleCompaniesOptions}
        onChange={onSelectTitleCompanies}
        text={getDropdownText('company', titleCompaniesSelected?.length, titleCompaniesOptions)}
        multiple
        search
        tags
        data-cy="dropdown_filters_title_companies"
      />

      <p className="vendors__description">Please select the surveyors you are affiliated with.</p>

      <Dropdown
        label="Surveyors"
        value={surveyorsSelected}
        options={surveyorsOptions}
        onChange={onSelectSurveyors}
        text={getDropdownText('surveyor', surveyorsSelected?.length, surveyorsOptions)}
        multiple
        search
        tags
        data-cy="dropdown_filters_surveyors"
      />
    </StyledVendors>
  );
};

export default Vendors;

const StyledVendors = styled.div`
  .vendors {
    &__description {
      margin: 0 0 24px;
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
      & ~ p {
        margin: 48px 0 24px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    .dropdown {
      width: 100%;
    }
  }
`;
